import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const RoutesComponent = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/:path" element={<App />} />

    </Routes>
  </Router>
);

const RouterHTML = () => {
  const url = new URL(window.location.href);
  const paramValue = url.searchParams.get('category');    // Obter a URL completa

  return <App path={paramValue} />
}

root.render(
  /* <RoutesComponent />*/
  <RouterHTML />
);

reportWebVitals();
