import { PageProps } from './typePages'

type Props = {}

export const SubCategory = ({show, children}: Props & PageProps) => {
  return (
    <div style={{
       display: show ? 'block' : 'none'
    }}>
        {children}
    </div>
  )
}