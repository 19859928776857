import { CardActionArea, CardContent, CardMedia, Card as MUICard, Typography } from '@mui/material';
import React from 'react';
import { Orientation } from '../App';

type Props = {
  title: string;
  image: string;
  onClick?: () => void;
  orientation: Orientation;
};

export const Card: React.FC<Props> = ({ title, image, onClick, orientation }) => {
  return (
    <MUICard
      style={{
        marginBottom: '10px',
        borderRadius: '15px', // Deixa a borda mais arredondada
        overflow: 'scroll',
        height: '260px',
        flex: "0 0 260px", // Garante que o conteúdo siga as bordas arredondadas
        margin:"20px",
        width: 260, // Ajuste conforme necessário
      }}
    >
      <CardActionArea onClick={onClick} style={{display: "flex", flexDirection:"column", overflow: 'hidden'}}>
        <img
         
          src={image}
          alt={title}
          style={{  objectFit: 'cover', height:   orientation === "portrait" ? "225px" : "216px" ,
             maxHeight:"100vh", maxWidth:"100vw", margin: "0 auto" }} // Mantém a imagem centralizada e coberta
        />
        <CardContent
          style={{
            backgroundColor: '#6A1B9A', // Cor de fundo roxa
            padding: '10px',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Typography variant="body1" component="div" style={{ color: '#FFFFFF', textTransform: 'uppercase', fontSize:  "1.2vw" }}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </MUICard>
  );
};
