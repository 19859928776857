import { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import './App.css';
import { Card } from './components/Card';
import { Category } from './pages/Category';
import { Product } from './pages/Product';
import { SubCategory } from './pages/SubCategory';



const importAll = (r: any) => {
  const files: Record<string, string> = {};
  r.keys().forEach((item: string) => {
    const key = item.replace('./', '');
    files[key] = r(item).default || r(item); // Adiciona .default para importar corretamente com Webpack
  });
  return {
    data: files,
    length: Object.keys(files).length,
  };
};

const images = importAll((require as any).context('./assets/images', false, /\.(mp4|pdf|png|jpe?g|svg)$/));
const videos = importAll((require as any).context('./assets/videos', false, /\.(mp4|pdf|png|jpe?g|svg)$/));
const lanches = importAll((require as any).context('./assets/lanches', false, /\.(mp4|pdf|png|jpe?g|svg)$/));
const Icons = importAll((require as any).context('./assets/icons', false, /\.(mp4|pdf|png|jpe?g|svg)$/));

console.log(images);
console.log(videos);
console.log(lanches);
console.log(Icons);


type TCards = "Category" | "SubCategory" | "Product" | "ProductImage" | "ProductVideo"

type ProductID = `p${number}`;

interface IProduct {
  id: ProductID;
  name: string;
  price: number;
  url_video?: string;
  url_imagem?: string;
  url_imagem_2: string;
  capa: string;
}

const getCardLevel = (cardName: TCards): number => {
  switch (cardName) {
    case "Category": return 1;
    case "SubCategory": return 2;
    case "Product": return 3;
    case "ProductImage": return 4;
    case "ProductVideo": return 5;
    default: return 1;
  }
};

const MapCardNametoLevel: Record<number, TCards> = {
  1: "Category",
  2: "SubCategory",
  3: "Product",
  4: "ProductImage",
  5: "ProductVideo",
}


interface ICategory {
  id: string;
  name: string;
  hasSubcategory: boolean;
  title_image: string;
  subCategory?: ICategory[];
  imageSubCategory?: string;
  products?: IProduct[];
}

declare global {
  interface Window {
    products:ICategory[];
  }
}



const products: ICategory[] = window.products
console.log(products)

type TFullScreen = {
  content: string | null,
  show?: boolean,
  CardName: TCards,
  setCardName: (prevState: TCards) => void;
  lastCard: MutableRefObject<number>;
}


const FullScreenImage = ({ content, onClickLayer1, show = false, CardName, setCardName, lastCard }: TFullScreen & { onClickLayer1: () => void }) => {
  const orientation = useOrientation()
  const handleClickLayer1 = () => {
    setCardName("Category")
  }

  const handleClickLayer2 = () => {
    console.log(lastCard?.current)

    if (lastCard.current) {
      setCardName(MapCardNametoLevel[lastCard.current])
    }



  }
  return (
    <>
      {
        content && <FullScreenOverlay show={show} orientation={orientation}
          style={{ backgroundColor: images.data["BG1340.png"] }}
        >
          <LayerToo handleClickLayer1={handleClickLayer1} handleClickLayer2={handleClickLayer2} />
          <Layer1 onClick={onClickLayer1} orientation={orientation} >
            <img src={Icons.data["icone_video.png"]} alt="" />
          </Layer1>

          <img src={content} alt="Product" />



        </FullScreenOverlay>
      }
    </>
  )
}




type RVoid = () => void

const LayerToo = ({ screen = "ProductImage", handleClickLayer1, handleClickLayer2, icon_voltar = "icon_voltar.png" }:
  { screen?: string, icon_voltar?: string, handleClickLayer1: RVoid, handleClickLayer2: RVoid }) => <>
    <div className='layer1' onClick={handleClickLayer1}>
      <img src={Icons.data["icon_inicio.png"]} alt="" />
    </div>
    <div className='layer2' onClick={handleClickLayer2} style={{ marginTop:  5 }}>
      <img src={Icons.data[icon_voltar]} alt="" />
    </div> </>


const FullScreenVideo = ({
  id,
  content,
  show = false,
  CardName,
  setCardName,
  lastCard,
  videoRef,
}: TFullScreen & { id: string; videoRef: React.RefObject<HTMLVideoElement> }) => {
  const handleClickLayer1 = () => {
    setCardName("Product");
  };

  const [showP, setShow] = useState<boolean>(show)

  useEffect(() => {
    if (videoRef.current && content) {
      videoRef.current.src = content;
      videoRef.current.load(); // Força o carregamento do vídeo
    }
    const handlePlay = () => {
      setShow(true);
    };

    const handleEnd = () => {
      console.log('end')
      setShow(false);
    };

    if( videoRef?.current){
      videoRef.current.addEventListener('play', handlePlay);
      videoRef.current.addEventListener('ended', handleEnd);
    }


    // Limpeza ao desmontar o componente
    return () => {
      videoRef.current?.removeEventListener('play', handlePlay);
      videoRef.current?.removeEventListener('ended', handleEnd);
    };
  }, [content]);

  useEffect(() => {
    if (CardName === "ProductVideo") {
      if( videoRef.current){
        videoRef.current?.play();
      //  videoRef.current.onplay(() => setShow(true))
      }
      

    } else if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [CardName]);

  const handleClickLayer2 = () => {
    console.log(lastCard?.current);

    if (lastCard.current) {
      setCardName(MapCardNametoLevel[lastCard.current]);
      setShow(false);
    }
  };

  const handleVideoEnded = () => {
    setCardName("ProductImage");
  };

  const orientation = useOrientation();

  return (
    <>
      {content && (
        <FullScreenOverlay  show={showP} orientation={orientation} id={id}>
          <LayerToo  
            screen={"ProductVideo"} 
            handleClickLayer1={handleClickLayer1} 
            handleClickLayer2={handleClickLayer2} 
            icon_voltar={"icon_voltar_2.png"} 
          />
          <video
            muted
            autoPlay
            ref={videoRef}
            onEnded={handleVideoEnded}
          >
            <source type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </FullScreenOverlay>
      )}
    </>
  );
};
interface PropsLayer1 {
  orientation: Orientation;
}

const Layer1 = styled.div<PropsLayer1>`
    width: 297px;
    height: 90px;
    position: absolute;
    top: ${props => props.orientation === 'portrait' ? 88 : 78}vh;
    right:${props => props.orientation === 'portrait' ? 31 : 3}vW;
  
    background-size: 234px;
    cursor: pointer;
`

export type Orientation = "portrait" | "landscape";

export const useOrientation = (): Orientation => {
  const [orientation, setOrientation] = useState<Orientation>(
    window.innerHeight > window.innerWidth ? "portrait" : "landscape"
  );

  useEffect(() => {
    const handleResize = () => {
      setOrientation(window.innerHeight > window.innerWidth ? "portrait" : "landscape");
    };

    window.addEventListener('resize', handleResize);

    // Limpeza ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return orientation;
};


function App({ path }: { path?: string | null }) {
  const orientation = useOrientation()
  ///const { path } = useParams()

  const [CardName, setCardName] = useState<TCards>("Category");
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);
  const [FullScreenImageState, setFullScreenImageState] = useState<string | null>(null);
  const [FullScreenVideoState, setFullScreenVideoState] = useState<string | null>(null);
  const [currentCapa, setCurrentCapa] = useState<IProduct | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);



  const isGelados = path === "gelados";
  const isSanduiches = path === "sanduiches"

  console.log(orientation)
  const lastCard = useRef<number>(0);

  useEffect(() => {
    const category = isGelados ? products[0] : isSanduiches ? products[1] : null
    if (category) {

      setSelectedCategory(category);
      if (category.hasSubcategory && category.subCategory) {
        setCardName("SubCategory");
      } else {
        setCardName("Product");
      }
    }
  }, [])
  useEffect(() => {
    console.log(lastCard)

    lastCard.current = getCardLevel(CardName) - 1;
  }, [CardName]);

  console.log("render App")
  const handleCategoryClick = (category: ICategory) => {
    setSelectedCategory(category);
    if (category.hasSubcategory && category.subCategory) {
      setCardName("SubCategory");
    } else {
      setCardName("Product");
    }
  };


  const handleSubCategoryClick = (subCategory: ICategory) => {
    setSelectedCategory(subCategory);
    setCardName("Product");
  };

  const handleProductClick = (product: IProduct) => {
    //const imageUrl = `/assets/images/${product.capa}.png`;
    setFullScreenImageState(images.data[product.url_imagem_2]);
    setCurrentCapa(product)
    setCardName("ProductImage")


  };
  const handleClickVoltarSub = () => {
    console.log(lastCard?.current)

    if (lastCard.current) {
      setCardName(MapCardNametoLevel[lastCard.current])
    }
    if (path) {
      const cat = products.find((cat, idx) => { if (cat.name === path) return cat });
      cat && setSelectedCategory(cat)
    }

  }

  const handleLayer1Click = () => {

    if (currentCapa?.url_video) {
      setFullScreenVideoState(videos.data[currentCapa.url_video])
    }

    setCardName("ProductVideo");
  };


  return (
    <AppStyled orientation={orientation}>
      <Category show={CardName === "Category"}>
        {products.map(category => (
          <Card
            orientation={orientation}
            image={lanches.data[category.name + ".png"]}
            key={category.id}
            title={category.name}
            onClick={() => handleCategoryClick(category)}
          />
        ))}
      </Category>



      {CardName === "SubCategory" && selectedCategory?.imageSubCategory &&
        <div className='image_sub'>
          <img src={images.data[selectedCategory.imageSubCategory]} alt="" />
        </div>
      }


      <SubCategory show={CardName === "SubCategory"}>


        <div style={{ padding: "81px 0", display: "block" }}>
          {selectedCategory?.subCategory?.map(cat => (
            <>{cat &&
              <div onClick={() => handleSubCategoryClick(cat)} className='subCategoryGelados' />
            }

            </>

          ))}
        </div>

      </SubCategory>
      {
        CardName === "Product" && selectedCategory?.title_image &&
        <div className='title_product'>
          <img src={Icons.data[selectedCategory.title_image]} alt='' />
        </div>
      }
      {(selectedCategory?.name === "maquina sorvete" ||
        selectedCategory?.name === "maquina_shake") &&
        CardName === "Product"
        && <div onClick={handleClickVoltarSub} style={{
          position: "absolute",
          top: 0,
          left: 0
        }} className='icon_voltar'>
          <img src={Icons.data['icon_voltar_2.png']} alt="" />
        </div>


      }
      <Product show={CardName === "Product"} >


        {
          /**<div style={{
          position:'absolute',
          left:0,
          top: 0,
          width: '67px',
          height: '68px'
        }} >
          <img src={Icons.data["icon_voltar_2.png"]} alt="" />
        </div> */
        }

        {selectedCategory?.products?.map(product => (
          <Card
            orientation={orientation}
            image={lanches.data[product.capa + ".png"]}
            key={product.id}
            title={product.name}
            onClick={() => handleProductClick(product)}
          />
        ))
        }
      </Product>


      <FullScreenImage
        show={CardName === "ProductImage"}
        content={FullScreenImageState}
        onClickLayer1={handleLayer1Click}
        CardName={CardName}
        lastCard={lastCard}
        setCardName={setCardName}
      />



      <FullScreenVideo
        show={CardName === "ProductVideo"}
        content={FullScreenVideoState}
        CardName={CardName}
        lastCard={lastCard}
        id='teste1'
        setCardName={setCardName}
        videoRef={videoRef}
      />

    </AppStyled>
  );
}

interface Props {
  show: boolean;
  orientation: Orientation;
}

const FullScreenOverlay = styled.div<Props>`
  display: ${props => props.show ? "flex" : "none"} ;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
 
  >.layer1{
    position: absolute;
    width: 67px;
    height: 68px;
    left:  -5px;
    top: 0px;
    z-index: 2;
    display: none ;
     img {
       max-width: 100%;
       max-height: 100%;
  }
  }
  >.layer2{
    position: absolute;
    width: 67px;
    height: 68px;
    left: 0 ;
    top: 0px;
    z-index: 2;
      img {
      max-width: 100%;
      max-height: 100%;
  }
  }
  img, video {
    max-width: 100%;
    max-height: 100%;
  }
`;

interface IAppleStyled {
  orientation: Orientation;
}

const AppStyled = styled.div<IAppleStyled>`
  height: 100vh;
  width: 100vw;
    background-image: url(${images.data["BG1340.png"]});
    .title_product {
      position: absolute;
     width: 100%;
     height: ${({ orientation }) => orientation === 'portrait' ? "85px" : ""};
     display: flex;
     
      img{
        margin: 0 auto;
      }
    }

    .image_sub{
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      img{
        max-width: 100%;
        width: 100%;
      }
    }
    .subCategoryGelados{
      height: 46vh;
    position: relative;
    width: 76vw;
   
    margin: 12px auto 39px auto;
    z-index: 2;
    }

`;

export default App;
