import { PageProps } from './typePages';

type Props = {
}

export const Category = ({ show, children }: Props & PageProps) => {
    return (
        <div style={{
            display: show ? 'flex' : 'none',
            justifyContent: "space-around",
            alignItems: "center",
            height: "100vh"
        }}>
            {children}
        </div>
    )
}

