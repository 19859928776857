import styled from 'styled-components'
import { useOrientation } from '../App'
import { PageProps } from './typePages'

type Props = {
}

export const Product = ({ show, children, }: Props & PageProps) => {
  const orientation = useOrientation()

  return (
    <ProductStyled style={{
      display: show ? 'flex' : 'none',
      flexDirection: orientation === "landscape" ? "column" : "row",
      overflow: "scroll",
      height: "93%",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
      position: "absolute",
      width: "96vw",
      left: "2vw",
      top: "6vh"

    }} className={"Product"}>
      {children}
    </ProductStyled>
  )
}

const ProductStyled = styled.div`

`